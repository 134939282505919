<template>
  <v-container class="px-0">
    <v-card>
      <v-card-text>
        <v-container class="px-0">
          <validation-observer ref="observer">
            <form @submit.prevent="submit">
              <v-row align="center" justify="left">
                <v-col cols="auto" class="pb-0">
                  <p class="primary--text font-weight-bold">パスワード再発行</p>
                </v-col>

                <v-col cols="12" class="pb-0">
                  <p class="text--secondary mb-3">
                    パスワード再発行用のメールを送信します。
                    登録メールアドレスを入力してください。
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required:メールアドレス|email"
                  >
                    <v-text-field
                      v-model="email"
                      placeholder="mail@crosspoint.com"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      solo
                      background-color="#EAEDF0"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <v-btn
                    color="primary"
                    block
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                    @click="submit"
                  >
                    送信
                  </v-btn>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <v-btn color="grey" dark block :to="{ name: 'login' }">
                    戻る
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ForgotPasswordPage",
  data() {
    return {
      email: ""
    };
  },
  computed: {
    ...mapGetters(["getApiProcessingStatus"])
  },
  methods: {
    submit() {
      let email = this.email;
      let reset_url = `${process.env.VUE_APP_DEV_URL}/reset-password/`;

      this.$store.dispatch("AUTH_FORGOT", { email, reset_url }).then(
        response => {
          if (response.status === 200) {
            this.$router.push("reset_password");
          }
        },
        error => {
          console.error(error);
        }
      );
    }
  }
};
</script>

<style scoped></style>
